import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import { on } from "process";
import { useHook } from "app/hooks/common";
import { isCreator } from "app/core/common";

export default function MobileMenu({ show, setShow }: any) {
  const location = useLocation();
  const history = useHistory();
  const leftOpen = "0";
  const leftClose = "-10px";
  const transition = "ease-in-out 250ms";

  const { userInfo } = useHook();

  const nav = [
    /* {
      name: "Awards",
      to: "/wma/list",
      icon: origin + "/assets/icons/wma",
      auth: false,
      type: "page",
    }, */
    /* {
      name: isCreator(userInfo?.role) ? "Earn Points" : "Earn $FANS Tokens",
      to: "/earn-points/",
      icon: origin + "/assets/icons/earn",
      auth: true,
      type: "page",
      creatorOnly: false,
      external: false,
    }, */
    /* {
      name: "Remix Contest",
      to: "/remix-contest/",
      icon: origin + "/assets/icons/earn",
      auth: false,
      type: "page",
      creatorOnly: true,
    }, */
    {
      name: "Wallet",
      to: `/user/${userInfo?.username ?? userInfo?.id}?tab=nfts`,
      icon: origin + "/assets/icons/wallet",
      auth: true,
      type: "page",
      tab: ["nfts"],
    },
    {
      name: "Collaboration Hub",
      to: "/collaboration",
      icon: origin + "/assets/icons/wma",
      auth: false,
      type: "page",
      creatorOnly: false,
    },
    {
      name: "Inbox",
      to: `/inbox/?tab=inbox`,
      icon: origin + "/assets/icons/profile",
      auth: true,
      type: "page",
      tab: ["inbox"],
    },
    {
      name: "Music Library",
      to: `/music-library`,
      icon: origin + "/assets/icons/profile",
      auth: true,
      type: "page",
    },
  ];

  return (
    <div>
      <div
        onClick={() => setShow(false)}
        className={styles.overlay}
        style={
          show
            ? {
                visibility: "visible",
                display: "block",
                opacity: 1,
                zIndex: 1,
                transition: transition,
              }
            : {
                visibility: "hidden",
                display: "none",
                opacity: 0,
                transition: transition,
              }
        }
      />
      <div
        className={styles.contentDrawer}
        style={
          show
            ? {
                left: leftOpen,
                visibility: "visible",
                display: "block",
                opacity: 1,
                zIndex: 1,
                transition: transition,
              }
            : {
                left: leftClose,
                display: "none",
                visibility: "hidden",
                opacity: 0,
                transition: transition,
              }
        }
      >
        <div className={styles.header}>
          <h3>Menu</h3>
          <button className={styles.close} onClick={() => setShow(false)}>
            <img src="/close.svg" />
          </button>
        </div>
        <hr />
        <ul className={styles.menuDrawer}>
          {nav.map((item: any, index: Number) => {
            return (
              <li
                className={location.pathname === item.to ? styles.active : ""}
                key={`item-${index}`}
                hidden={item.auth ? !Boolean(userInfo) : false}
              >
                <div
                  className={styles.item}
                  onClick={() => {
                    history.push(item.to);
                    setShow(false);
                  }}
                  style={{ padding: "10px 0" }}
                >
                  <img
                    alt={item.name}
                    src={
                      location.pathname === item.to
                        ? `${item.icon}_active.svg`
                        : `${item.icon}.svg`
                    }
                  />
                  <span>{item.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
