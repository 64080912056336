import styles from "./Creators.module.scss";
import { truncate } from "app/helpers/helper";
import React, { useState, useRef, useEffect, Dispatch } from "react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import Equalizer from "../equalizer/Equalizer";
import { DirectusImage } from "app/core/common";
import { Link } from "react-router-dom";
import { sendEvent } from "app/helpers/EventTracker";
import { useHook } from "app/hooks/common";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Creators({
  creator,
  myUser,
  handleVote,
  setPage,
  song,
  playingSong,
  setPlayingSong,
  creatorLaunchPad,
  handleOpenVote,
  collab,

  handleOpenMessage,
}: {
  creator?: any;
  myUser?: any;
  handleVote?: any;
  setPage?: any;
  song?: any;
  playingSong?: any;
  setPlayingSong?: any;
  creatorLaunchPad?: any;
  handleOpenVote?: any;
  collab?: any;

  handleOpenMessage?: (id) => void;
}) {
  const { cookie } = useHook();
  const [isHovered, setIsHovered] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const equalizerRef = useRef(null);

  const handlePlayer = () => {
    setPlayingSong(song);
    setCurrentSong(song);
    if (playingSong === currentSong) {
      setPlaying((prev) => !prev);
      !playing &&
        // Send Song Play Event
        sendEvent({
          cookie: cookie,
          event: "play_song",
          eventId: song,
        });
    } else {
      setPlaying(false);
      // Send Song Play Event
      sendEvent({
        cookie: cookie,
        event: "play_song",
        eventId: song,
      });
    }
  };

  useEffect(() => {
    if (collab) {
      return;
    }
    if (playingSong === song) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  }, [playingSong]);

  useEffect(() => {
    // Logic to stop playing when component unmounts
    return () => {
      setPlaying(false);
    };
  }, []);

  return (
    <div
      className={styles.creators_container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <LazyLoadImage
        src={DirectusImage(creator?.avatar?.id)}
        alt={`${creator?.id}`}
        effect="blur"
        wrapperClassName={styles.image}
      />
      <div className={styles.overlay} />
      {/* {isHovered &&
        (collab ? (
          <div
            className={styles.vote_button}
            onClick={() => handleOpenMessage(creator.id)}
          >
            <span>Collaborate</span>
          </div>
        ) : (
          <div
            className={styles.vote_button}
            onClick={() => handleOpenVote(creatorLaunchPad)}
          >
            <span>
              {myUser?.votedArtistId === creator?.id ? "Voted" : "Vote"}
            </span>
          </div>
        ))} */}
      {collab ? (
        <div
          className={styles.vote_button}
          onClick={() => handleOpenMessage(creator.id)}
        >
          <span>Collaborate</span>
        </div>
      ) : (
        <div
          className={styles.vote_button}
          onClick={() => handleOpenVote(creatorLaunchPad)}
          /* to={`/collection/${creatorLaunchPad[0]?.info?.slug}`} */
        >
          <span>
            {myUser?.votedArtistId === creator?.id ? "Voted" : "Vote"}
          </span>
        </div>
      )}
      {!playing ? null : (
        <Equalizer
          playing={playing}
          equalizerRef={equalizerRef}
          setPlaying={setPlaying}
          song={song}
        />
      )}
      <div className={styles.club_details}>
        <div className={styles.details}>
          <Link to={`/user/${creator.username ?? creator.id}?tab=collections`}>
            {" "}
            <p>{creator?.display_name ?? creator?.first_name}</p>
          </Link>
          <div className={styles.content}>{creator?.description}</div>
        </div>
        {song && (
          <div className={styles.club_details_bottom}>
            <div className={styles.club_details_bottom_song}>
              <span>
                {collab ? "Featured song uploaded by" : "WMA song uploaded by"}(
                {creator?.display_name ?? creator?.first_name})
              </span>
            </div>
            {song && (
              <div
                className={styles.club_details_bottom_icons}
                onClick={() => handlePlayer()}
              >
                {!playing ? (
                  <PlayCircleOutlineIcon fontSize="large" />
                ) : (
                  <PauseCircleOutlineIcon fontSize="large" />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
