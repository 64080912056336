import { useEffect, useState } from "react";
import Modal from "../Modal";
import Button from "../button/button";
import styles from "./login.module.scss";
import LoginPage from "app/pages/Login/Login";
import { useHook } from "app/hooks/common";
import logo from "../../../assets/logo.png";
import checkmark from "../../../assets/icons/check.svg";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { useLogin } from "app/context/loginProvider";
import Input from "../input/input";
import axios from "axios";
import Loader from "../Loader";
import InstaGramPage from "../instagramModal";

interface LoginProps {
  showLoginModal: boolean;
  showSignup: boolean;
  artistSignup?: boolean;
  showRegister?: boolean;
}

export default function LoginModal(props: LoginProps) {
  const { showLoginModal, showSignup, artistSignup, showRegister } = props;
  const { address } = useHook();
  const { toggleLoginModal, toggleSignupModal, toogleRegisterModal } =
    useLogin();
  const checkList = t("signupCheckList");

  const [error, setError] = useState("");
  const [emailToCheck, setEmaiToCheck] = useState("");
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(false);
  const [instagramModal, setInstagramModal] = useState(false);

  const ua = navigator.userAgent;
  const isInstagram = ua.indexOf("Instagram") > -1 ? true : false;

  const checkRegisteredUser = async () => {
    try {
      if (!emailToCheck) {
        setError("Please enter an email address");
        return;
      } else if (isInstagram) {
        setInstagramModal(true);
        return;
      }
      setError("");
      setLoading(true);
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_MIDDLEWARE}user/action/checkAccount`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email: emailToCheck,
        },
      });

      const check = response.data;

      if (check.account === "created") {
        setRegister(true);
        setLogin(false);
      } else if (check.account === "exists") {
        setLogin(true);
        setRegister(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (address && showLoginModal) {
      toggleLoginModal();
    }
  }, [address]);

  return (
    <div className={styles.login_modal}>
      <Modal
        isOpen={showLoginModal}
        title=""
        onClose={() => {
          toggleLoginModal();
          toogleRegisterModal(false);
          toggleSignupModal(false);
          setError("");
          setRegister(false);
          setLogin(false);
          setInstagramModal(false);
        }}
        customClass="pauseModal"
        login={true}
      >
        <div
          style={{ textAlign: "center", marginBottom: "50px" }}
          className="pauseCard"
        >
          <img className={styles.logo} src={logo} alt="logo" />
          {!isInstagram && showSignup ? (
            <>
              {loading ? (
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>
                    <Trans>loginTitle</Trans>
                  </h3>
                  <LoginPage
                    artistSignup={artistSignup}
                    showSignup={showSignup}
                    setSignupform={toggleSignupModal}
                    register={register}
                  />
                </div>
              )}
            </>
          ) : null}

          {showRegister || isInstagram ? (
            <>
              {register ? null : (
                <h3>
                  <Trans>loginTitle</Trans>
                </h3>
              )}

              <br />
              <br />
              {loading ? (
                <div
                  style={{
                    position: "relative",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Loader />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!login && !register && !instagramModal ? (
                    <>
                      <Input
                        placeholder="Enter your email"
                        value={emailToCheck}
                        setValue={setEmaiToCheck}
                        classes={styles.input}
                        checkRegisteredUser={checkRegisteredUser}
                      />
                      <Button onClick={() => checkRegisteredUser()} size="full">
                        Continue
                      </Button>
                      {error ? <p style={{ color: "red" }}>{error}</p> : null}
                    </>
                  ) : login && !register ? (
                    <div className={styles.content}>
                      <LoginPage
                        artistSignup={artistSignup}
                        showSignup={showSignup}
                        setSignupform={toggleSignupModal}
                        register={register}
                      />
                    </div>
                  ) : !login && register ? (
                    <LoginPage
                      artistSignup={artistSignup}
                      showSignup={showSignup}
                      setSignupform={toggleSignupModal}
                      register={register}
                      showRegister={showRegister}
                    />
                  ) : null}

                  {instagramModal && !login && !register ? (
                    <InstaGramPage />
                  ) : null}
                </div>
              )}
            </>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
