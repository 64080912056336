import { useEffect, useState } from "react";
import styles from "./Profile.module.scss";
import { useHook } from "app/hooks/common";
import * as api from "app/api/collections.api";
import VoteModal from "app/components/leaderboard/vote_modal/voteModal";
import { CreatorCollections } from "app/core/common";
import NftDrops from "app/components/nft_drops_/NftDrops";

export default function VotingView({ userId, setCollection, setVoting }) {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getCreatorCollections({ username: userId, limit: 1, type: "vote" }).then((data) => {
      setCollections(data);
      setLoading(false);
    });
  }, []);

  const handleVote = ({ item }) => {
    setCollection(item);
    setVoting(true);
  };

  return (
    <>
      <div className={styles.profile_vote}>
        {loading ? (
          <></>
        ) : (
          collections?.map((item, index) => (
            <div
              className={styles.vote_button}
              onClick={() => handleVote({ item })}
              key={index}
            >
              <img src={"/assets/icons/wma_active.svg"} /> Vote for me in the W3 Awards
            </div>
          ))
        )}
      </div>
    </>
  );
}
