import React from "react";
import styles from "./styles.module.scss";

export default function VideoCard({ video }) {
  return (
    <div className={styles.videos}>
      <video src={video.url} controls loop autoPlay={false}></video>
    </div>
  );
}
