import { useEffect, useState } from "react";
import SwiperCore from "swiper";
import "swiper/swiper.scss";
import CountdownComp from "./countdown";

import styles from "./index.module.scss";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import {
  CurrencyRateType,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { textTruncate } from "app/helpers/helper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// import blue from "@mui/material/colors/blue";

const UpcomingForm = ({
  queryResult,
  launchpadNFT,
  launchpad_imageData,
  currencyRate,
  index,
  quantity,
  setQuantity,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT?: LaunchPadNFT;
  launchpad_imageData?: any;
  currencyRate?: CurrencyRateType;
  index?: Number;
  quantity?: number;
  setQuantity?: any;
}) => {
  const { address } = useHook();
  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];

  // function to increment the quantity of NFTs to mint
  const handleIncrement = () => {
    setQuantity((prevQuantity: number) => prevQuantity + 1);
  };
  // function to decrease the quantity of NFTs to mint
  const handleDecrement = () => {
    setQuantity((prevQuantity: number) =>
      prevQuantity > 1 ? prevQuantity - 1 : 1
    );
  };

  return (
    <>
      <div className={styles.project_social}>
        {/* <img src={notify} alt='thumb' /> */}
        {/* <Button
          className={styles.fav_btn}
          loading={isFavsLoading}
          disabled={!address}
          onClick={() => handleFav()}
        >
          <img src={isfav ? favA : fav} alt="thumb" />
        </Button> */}
      </div>

      <div className={styles.nft_view}>
        <div className={styles.header}>
          <div className={styles.desc}>
            <b
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].fan_collection
                    .name,
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].fan_collection
                    .description,
              }}
            />
          </div>
          {/* Creator Info Section */}
          <div className={styles.artist}>
            <Link
              to={`/user/${
                launchpadNFT?.artist.username ?? launchpadNFT?.artist.id
              }`}
              className={styles.profile}
            >
              <img
                src={
                  "https://loop-markets.directus.app/assets/" +
                  launchpadNFT?.artist.avatar.id
                }
                alt="thumb"
              />
              <div>
                <span>
                  <>CREATOR</>
                </span>
                <p>
                  {textTruncate(
                    launchpadNFT?.artist.display_name ??
                      launchpadNFT?.artist.first_name,
                    12
                  )}
                </p>
              </div>
            </Link>
            <div className={styles.divider} />
            <div>
              <span>
                <>Collection Type</>
              </span>
              <p style={{ textTransform: "capitalize" }}>
                <>{launchpadNFT?.collection_type}</>
              </p>
            </div>
          </div>

          {/* Max Supply Section */}
          {/* <div className={styles.mint_live}>
            <div>
              <p className={styles.price_text}>Max Supply</p>
            </div>

            <button className={styles.mint_countdown}>
              {launchpadNFT.launch_tba ? (
                <span className={styles.soon}>
                  <>Coming Soon</>
                </span>
              ) : (
                <span>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              )}
            </button>
          </div>

          <hr /> */}

          {/* Start Date Section */}
          <div className={`${styles.mint_live} ${styles.mint_live_border}`}>
            <div>
              <p className={styles.price_text}>Start Date</p>
            </div>

            <button className={styles.mint_countdown}>
              <span className={styles.countdown}>
                ...
                {/* <CountdownComp
                  launchpadNFT={launchpadNFT}
                  index={Number(index)}
                /> */}
              </span>
            </button>
          </div>

          {/* NFT Mint Section */}
          <div className={`${styles.mint_live} ${styles.mint_live_border}`}>
            <div className={styles.price_infos}>
              <div>
                <p className={styles.price_text}>
                  <Trans>price</Trans>
                </p>
                <p className={styles.price_value}>
                  {price == 0
                    ? `Free`
                    : `$${
                        parseFloat(Number(price).toLocaleString()) * quantity
                      }`}
                </p>
              </div>
              {!address ? null : (
                <div className={styles.quantityContainer}>
                  <button
                    onClick={handleIncrement}
                    className={styles.incrementButton}
                    id="add"
                    disabled={true}
                  >
                    <AddIcon />
                  </button>

                  <span className={styles.quantity}>{quantity}</span>
                  <button
                    onClick={handleDecrement}
                    className={styles.decrementButton}
                    disabled={true}
                  >
                    <RemoveIcon />
                  </button>
                </div>
              )}
            </div>

            <button className={styles.buy} disabled>
              {launchpadNFT?.launch_tba ? (
                <span className={styles.soon}>
                  <>Coming Soon</>
                </span>
              ) : (
                <span className={styles.soon}>
                  <>Starting Soon</>
                </span>
              )}
            </button>
          </div>
        </div>

        {/* Warn user that NFT will be random */}
        {launchpadNFT?.launchpad_type[Number(index)]?.type_gallery.length >
          1 && (
          <p style={{ textAlign: "center", color: "#677599" }}>
            <Trans>mintNote</Trans>
          </p>
        )}
      </div>
    </>
  );
};

export default UpcomingForm;
