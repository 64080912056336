import { createContext, useContext } from 'react';
import { ContextApi, Theme } from './types';

// @ts-ignore
export const NotificationContext = createContext<ContextApi>({
  theme: {
    transaction_type: "",
    type: undefined,
    subType: undefined,
    txLogs: undefined
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {},
});

export const useNotification = () => {
  const notificationContext = useContext(NotificationContext);
  const notificationEvent = ({
    transaction_type,
    subType,
    txErrorMessage,
    redirectURL,
    txHash,
  }: {
    transaction_type?: string;
    type?: number;
    subType: number;
    txErrorMessage?: string;
    redirectURL?: string;
    txHash?: string;
  }) => {
    notificationContext.setTheme({
      type: 0,
      transaction_type: transaction_type,
      subType: subType,
      ...(txErrorMessage && { txErrorMessage: txErrorMessage }),
      ...(redirectURL && { redirectURL: redirectURL }),
      ...(txHash && { txHash: txHash }),
    });
  };

  return {
    notificationEvent,
  };
}