import React from "react";
import styles from "./UnderProfileTab.module.scss";

export default function UnderProfileTab({
  underTabName,
  handleUnderTab,
}: {
  underTabName?: string;
  handleUnderTab?: (name: string) => void;
}) {
  return (
    <div className={styles.tabs}>
      <div
        className={`${styles.tab} ${
          underTabName == "creator_posts" || underTabName == ""
            ? styles.active
            : ""
        }`}
        onClick={() => handleUnderTab("creator_posts")}
      >
        <span>Creator</span>
      </div>
      <div className={styles.border}></div>
      <div
        className={`${styles.tab} ${
          underTabName == "community_posts" ? styles.active : ""
        }`}
        onClick={() => handleUnderTab("community_posts")}
      >
        <span>Community</span>
      </div>
    </div>
  );
}
