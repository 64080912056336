import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import folder from "../../../assets/LOOP-FANS-FOLDER.png";
import Button from "../button/button";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { DirectusAssets } from "app/core/common";
import JSZip from "jszip";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ClipLoader } from "react-spinners";

export default function File_Benefit({
  file,
  collection,
}: {
  file?: any;
  collection?: any;
}) {
  const [showContent, setShowContent] = useState<boolean>(false);
  const [fileList, setFileList] = useState<string[]>([]);

  useEffect(() => {
    if (showContent && file) {
      const fetchAndExtractZip = async () => {
        try {
          const response = await fetch(DirectusAssets(file.files[0].file.id));
          const blob = await response.blob();
          const zip = await JSZip.loadAsync(blob);
          const files = [];

          zip.forEach((relativePath, zipEntry) => {
            files.push(relativePath);
          });

          setFileList(files);
        } catch (error) {
          console.error("Failed to fetch or extract zip file:", error);
        }
      };

      fetchAndExtractZip();
    }
  }, [showContent, file]);

  const handleDownload = async () => {
    if (collection) {
      return;
    } else {
      try {
        const response = await fetch(DirectusAssets(file.files[0].file.id));
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${file.name}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download zip file:", error);
      }
    }
  };

  return (
    file && (
      <div className={styles.fan_media}>
        <div className={styles.info_header}>
          <h6>File</h6>
        </div>
        <div className={styles.folder_info}>
          <img src={folder} alt="" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div className={styles.folder_content}>Name: {file?.name}</div>
            {collection ? null : (
              <Button onClick={() => handleDownload()}>Download</Button>
            )}
          </div>
        </div>
        <div className={styles.file_content}>
          <h1 onClick={() => setShowContent((prev) => !prev)}>
            View File Contents
            {showContent ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </h1>
          {showContent ? (
            <div>
              {fileList.length > 0 ? (
                <ul>
                  {fileList.map((fileName, index) => (
                    <li key={index}>{fileName}</li>
                  ))}
                </ul>
              ) : (
                <ClipLoader />
              )}
            </div>
          ) : null}
        </div>
      </div>
    )
  );
}
