import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { PlayCircleOutline } from "@mui/icons-material";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { DirectusAudio, DirectusImage } from "app/core/common";
import { useMediaPlayer } from "app/context/mediaPlayerContext";

export default function MusicTable({
  track,
  index,
  isPlaying,
  handlePlayPause,
}: {
  track: any;
  index: number;
  isPlaying: boolean;
  handlePlayPause: () => void;
}) {
  const audioRef = useRef(new Audio());
  const [totalTime, setTotalTime] = useState(0);
  const { play, actualTrack } = useMediaPlayer();

  useEffect(() => {
    // Update audio source when track changes
    if (track?.track?.id) {
      const audio = audioRef.current;
      audio.src = DirectusAudio(track.track.id); // Set the new track URL
    }
  }, [track, isPlaying]);

  useEffect(() => {
    const audio = audioRef.current;
    const handleLoadedMetadata = () => setTotalTime(audio.duration);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [track]);

  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds === Infinity) {
      return "0:00";
    }
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const onPlayPauseClick = () => {
    handlePlayPause();
  };

  const isCurrentTrackPlaying =
    actualTrack?.track?.id === track.track.id && play;

  return (
    <tr
      className={`${styles.tableRow} ${
        isCurrentTrackPlaying ? styles.activeRow : ""
      }`}
      key={index}
    >
      <td>{index + 1}</td>
      <td>
        <img
          src={DirectusImage(track?.track?.artwork)}
          alt=""
          className={styles.tableImage}
        />
      </td>
      <td>{track?.track?.title}</td>
      <td>{track?.track?.artist?.username}</td>
      <td className={styles.duration}>{formatTime(totalTime)}</td>
      <td>
        {isCurrentTrackPlaying ? (
          <PauseCircleOutlineIcon
            className={styles.playIcon}
            onClick={onPlayPauseClick}
          />
        ) : (
          <PlayCircleOutline
            className={styles.playIcon}
            onClick={onPlayPauseClick}
          />
        )}
      </td>
    </tr>
  );
}
