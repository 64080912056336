import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import FadeLoader from "react-spinners/FadeLoader";
import styles from "./notification.module.scss";
import { useHistory } from "react-router-dom";
import Button from "../button/button";
import Confetti from "react-confetti";
import { use } from "i18next";
import { sendGleam } from "app/helpers/gleam";

const Complete = ({
  setTheme,
  transaction_type,
  txHash,
  redirectURL,
  txLogs,
  transactionInfo,
}: {
  setTheme: any;
  transaction_type: string | undefined;
  txHash: string | undefined;
  redirectURL: string | undefined;
  txLogs: any;
  transactionInfo?: any;
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  function onClose() {
    setTheme({
      type: undefined,
      subType: undefined,
    });
    if (redirectURL) {
      history.push(redirectURL);
      redirectURL === "/" && window.location.reload();
    } else {
      window.location.reload();
    }
  }

  const [recycle, setRecycle] = React.useState(true);
  useEffect(() => {
    setTimeout(() => {
      setRecycle(false);
    }, 3500);
  }, []);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, []);

  useEffect(() => {
    if (transaction_type === "vote" && !loading) {
      console.log("Sending gleam event");
      sendGleam({
        event: "vote",
        value: "voted",
      });
    }
  }, [transaction_type]);

  return (
    <>
      {loading ? (
        <Modal
          isOpen={true}
          title=""
          onClose={() => onClose()}
          zIndex={4}
          center
        >
          <div className={styles.notification_content}>
            <div>
              <FadeLoader color="#9b83f9" />
            </div>
          </div>
        </Modal>
      ) : (
        <>
          {" "}
          <Confetti className="Confetti" recycle={recycle} />
          <Modal
            isOpen={true}
            title=""
            onClose={() => onClose()}
            zIndex={4}
            center
          >
            <div className={styles.notification_content}>
              <img src={"/confetti.svg"} alt="wait" />
              <p className={styles.title}>
                Your Digital Collectible has been minted!
              </p>
              {/* <div className={styles.description}>
          View My Digital Pass
        </div> */}
              {/* <div className={styles.transactionId}>
          Transaction ID:
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://www.mintscan.io/juno/txs/${txHash}`}
          >
            {txHash}
          </a>
        </div> */}
              <div className={styles.actions}>
                <Button
                  className="btn_full btn_centered btn_solid"
                  onClick={() => {
                    setTheme({
                      type: undefined,
                      subType: undefined,
                    });
                    history.push("/earn-points");
                  }}
                >
                  Win $FANS
                </Button>
                <Button
                  className="btn_full btn_centered btn_solid"
                  onClick={() => onClose()}
                >
                  View your Digital Collectibles
                </Button>
              </div>

              <hr />
              <div className={styles.socials}>
                {/* Share to twitter and send image add #LoopFans */}
                <a
                  href={`https://twitter.com/intent/tweet?text=I%20just%20minted%20a%20Digital%20Collectible%20on%20%40LoopFans&url=${window.location.href}&hashtags=Web3MusicAwards,LoopFans,Web3`}
                  target="_blank"
                  className="btn_centered btn_outline social_link"
                >
                  <Button>
                    Share on{" "}
                    <img
                      style={{
                        border: "solid 2px #fff",
                        borderRadius: "50px",
                        marginLeft: "5px",
                      }}
                      width="20px"
                      height="20px"
                      src="/assets/icons/twitter.png"
                    />
                  </Button>
                </a>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Complete;
