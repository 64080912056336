import React, { useEffect, useRef, useState } from "react";
import styles from "./leaderboard/Creators.module.scss";

const AdComponent = ({ state, link }) => {
  const adRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (adRef.current) {
      // add the aso-zone class to the adRef
      /* adRef.current.classList.add("aso-zone");
      adRef.current.setAttribute("data-zone", "117491"); */
      setTimeout(() => setLoading(false), 1000);
      /* setTimeout(() => setScriptLoaded(true), 1000); */
    }
  }, [state]);

  return (
    <div ref={adRef} className={styles.loopserve}>
      {loading ? (
        <div
          className="loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        />
      ) : (
        <>
        <div
          className={styles.pr_button}
          /* to={`/collection/${creatorLaunchPad[0]?.info?.slug}`} */
        >
          <span>Promoted</span>
        </div>
        <iframe
          src={link}
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
        ></iframe>
        </>
      )}
    </div>
  );
};

export default AdComponent;
