import { useEffect, useRef, useState } from "react";
import styles from "./collections.module.scss";

import { Link, useLocation } from "react-router-dom";
import { DirectusImage, CourseUrl, CreatorCollections } from "app/core/common";
import * as api from "app/api/collections.api";
import Loader from "app/components/Loader";
import NftDrops from "app/components/nft_drops_/NftDrops";

interface props {
  username: string;
}

export default function ProfileCollections({ username }: props) {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getCreatorCollections({ username: username, limit: 0, type: 0 }).then((data) => {
      setCollections(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.courses}>
      {loading ? (
        <Loader />
      ) : (
        collections?.map((item, index) => (
          <NftDrops collection={item} key={index} />
        ))
      )}
    </div>
  );
}
