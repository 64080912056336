import { useMediaPlayer } from "app/context/mediaPlayerContext";
import styles from "./styles.module.scss";
import { DirectusImage } from "app/core/common";
import { truncate } from "app/helpers/helper";

interface Track {
  track: {
    id: string;
    title: string;
    artist: {
      username: string;
      avatar: string;
    };
    artwork: string;
    duration: number;
  };
}

export default function MusicList({
  track,
  handlePlaySong,
}: {
  track: Track;
  handlePlaySong: () => void;
}) {
  const { play, actualTrack } = useMediaPlayer();

  const isCurrentTrackPlaying =
    actualTrack?.track?.id === track.track.id && play;

  return (
    <div className={styles.music_list}>
      <div className={styles.music_list__item} onClick={handlePlaySong}>
        <img src={DirectusImage(track?.track?.artwork)} alt="" />
        <div className={styles.music_list__info}>
          <h4 className={isCurrentTrackPlaying ? styles.active : ""}>
            {truncate(track?.track?.title ?? track?.track?.title, 30, "...")}
          </h4>
          <p>
            {truncate(
              track?.track?.artist?.username ?? track?.track?.artist?.username,
              30,
              "..."
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
