import Creators from "app/components/leaderboard/Creators";
import { getParam, setParam } from "app/helpers/helper";
import { useHook } from "app/hooks/common";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Collaboration.module.scss";
import Modal from "app/components/Modal";
import Button from "app/components/button/button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function Collaboration() {
  const { userInfo, cookie } = useHook();
  const paramGenre = getParam("genre");
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [genre, setGenre] = useState(paramGenre ? paramGenre : 1);
  const [myUser, setMyUser] = useState(userInfo);
  const [moreFeed, setMoreFeed] = useState(true);
  const [page, setPage] = useState(1);
  const [genres, setGenres] = useState(null);
  const [divisions, setDivisions] = useState(1);
  const [collab, setCollab] = useState(false);
  const limit = 9;
  const textAreaRef = useRef(null);
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageText, setMessageText] = useState(null);
  const [sent, setSent] = useState(false);
  const [creatorId, setCreatorId] = useState(null);

  const [playingSong, setPlayingSong] = useState(null);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const history = useHistory();
  const location = useLocation();
  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };
  const showMore = () => {
    setPage((prev) => prev + 1);
  };

  /* ======================== */
  /* if there is no genre, set genre all  */
  /* ======================= */

  useEffect(() => {
    const paramGenre = getParam("genre");
    paramGenre && setGenre(paramGenre);
    // no param
    if (!paramGenre) {
      setParam("genre", 1);
    }
  }, [location.search]);

  /* ======================== */
  /* get the artist to show  */
  /* ======================= */

  useEffect(() => {
    //setPlayingSong(null);
    async function fetchData() {
      if (genre) {
        setLoading(true);
        try {
          const response = await axios({
            method: "get",
            url: `${process.env.REACT_APP_MIDDLEWARE}fans/creators
            `,
            headers: {
              "Content-Type": "application/json",
              page: page,
              limit: 10,
              genre: genre,
              collab: true,
            },
          });
          const data = await response.data;
          setCreators((prev) => [...prev, ...data]);
          setLoading(false);
        } catch (err) {
          // Handle any errors
          console.error(err);
          setLoading(false);
        }
      }
    }

    fetchData();
  }, [genre, page]);

  /* ======================== */
  /* get music genres */
  /* ======================= */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseGeners = await axios({
          method: "get",
          url: `${process.env.REACT_APP_MIDDLEWARE}arena/genres`,
        });

        const updatedGeners = responseGeners?.data?.genres.slice(1);

        // Add the new element at the beginning
        /* updatedGeners.unshift({ id: "0", name: "Votes by Genre" }); */

        setGenres(updatedGeners);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleRegisterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setCreators([]);
    setGenre(Number(value));
    setParam(name, value);
    setPage(1);
  };

  const handleChange = (e) => {
    // set aboutText with the text field
    const newText = e.target.value;

    if (e.target.name === "title") {
      setMessageTitle(newText);
    } else {
      setMessageText(newText);
    }

    // Automatically adjust height of the text field as the user types
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleSendMessage = async () => {
    if (messageText && messageTitle) {
      try {
        setLoading(true);

        try {
          await axios.post(
            `${process.env.REACT_APP_MIDDLEWARE}arena/inbox/createInbox`,
            {
              cookie,
              receiver_id: "fc11abe6-e8ed-4092-9ccf-1f5e3325e56c",
              title: messageTitle,
              message: messageText,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setSent(true);
        } catch (error) {
          setMessageText("");
          setMessageTitle("");
          setLoading(false);
          setCollab(false);
          setCreatorId("");
          console.error(error);
        }
        setTimeout(() => {
          setCreatorId("");
          setLoading(false);
          setCollab(false);
          setSent(false);
          setMessageText("");
          setMessageTitle("");
        }, 2000);
      } catch (error) {
        setCreatorId("");
        setMessageText("");
        setMessageTitle("");
        setLoading(false);
        setCollab(false);
        console.error(error);
      }
    } else {
      return;
    }
  };

  const handleOpenMessage = (id) => {
    setCreatorId(id);
    setCollab((prev) => !prev);
  };

  return (
    <div className={styles.clubs}>
      <div className={styles.leaderboard_title_subtitle_input}>
        <div className={styles.leaderboard_title}>
          <h3>Collaboration Hub</h3>
          <p className={styles.subHeading}>
            Explore collaborative opportunities and amplify your artistic impact
          </p>
        </div>
      </div>
      <div className={styles.leaderboard_options}>
        {/* <div className={styles.leaderboard_click_pages}>
      <LeaderBoardTabs tabName={tabName} handleTab={handleTab} />
    </div> */}
        <div className={styles.categories_select}>
          <div className={styles.select1}>
            <p>Explore artist by genre:</p>
            <select
              name="genre"
              id="genre"
              required
              onChange={handleRegisterChange}
              style={{ marginBottom: "10px" }}
              value={genre}
            >
              {genres &&
                genres?.map((gen) => (
                  <option key={gen?.id} value={gen?.id}>
                    {gen?.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      {creators.length > 0 ? (
        <div className={styles.club}>
          {creators?.map((creator, index) => (
            <Creators
              creator={creator?.artist ? creator?.artist : creator}
              creatorLaunchPad={creator?.launchpad}
              song={creator?.featured_song?.id}
              setPlayingSong={setPlayingSong}
              playingSong={playingSong}
              key={index}
              myUser={myUser}
              setPage={setPage}
              handleOpenMessage={handleOpenMessage}
              collab
            />
          ))}
          {moreFeed && (
            <InView as="div" onChange={handleInViewChange}>
              <div ref={ref} onClick={() => showMore()}></div>
            </InView>
          )}
        </div>
      ) : (null)}

        <div className="card" style={{maxWidth: "100%"}}>
          <h3>Want to appear here and start doing great collabs with other artists?</h3>
          <p>Head over to your profile, add your favourite genres, add a featured song and you'll be good to go!</p>
        </div>

      {/* {type === "result" ? (
    <LeaderBoardList
      genres={genres}
      genre={genre}
      division={division}
      divisions={divisions}
      setLoading={setLoading}
      clearPrevContent={() => clearPrevContent()}
    />
  ) : null} */}

      {loading && <div className="loader" />}
      {/* {voting ? <VoteModal creator={creator} /> : null} */}
      <Modal
        onClose={() => setCollab(false)}
        isOpen={collab}
        title={"Collaboration Request"}
        customSubClass="defaultModal"
      >
        <div className="defaultModalContent">
          {sent ? (
            <div className={styles.alert_message} role="alert">
              Message Sent!
              <CheckCircleOutlineIcon fontSize="large" color="success" />
            </div>
          ) : (
            <>
              <input
                name="title"
                type="text"
                className={styles.input_title_message}
                placeholder="Title"
                defaultValue={`Hey, I'll like to collaborate with you!`}
                onChange={handleChange}
              />
              <textarea
                name="message"
                ref={textAreaRef}
                className={styles.text_area_modal}
                value={messageText}
                onChange={handleChange}
                maxLength={500}
                placeholder="Message..."
              />
              <div className="defaultModalButtons">
                <Button
                  size="sm"
                  className="btn_none"
                  onClick={() => setCollab(false)}
                >
                  Cancel
                </Button>

                <Button
                  size="sm"
                  className="btn_danger"
                  onClick={() => handleSendMessage()}
                  loading={loading}
                >
                  Send
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
