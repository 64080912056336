import { useEffect, useRef } from "react";
import { DirectusImage } from "app/core/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";

const Project_Video = ({
  creator,
  video_id,
  collection,
}: {
  creator?: string;
  video_id?: any;
  collection?: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    let timeout: NodeJS.Timeout;

    const handlePlay = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (videoElement) {
          videoElement.pause();
          videoElement.currentTime = 0;
        }
      }, 5000); // 5 seconds
    };

    if (videoElement && collection) {
      videoElement.addEventListener("play", handlePlay);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        clearTimeout(timeout);
      }
    };
  }, [video_id, collection]);

  return (
    video_id && (
      <div className={styles.fan_media}>
        <div className={styles.info_header}>
          <h6>Video </h6>
          {collection ? (
            <span
              style={{
                background: "#22213d",
                padding: "5px 10px",
                borderRadius: "5px",
                fontWeight: "300",
                fontSize: "14px",
              }}
            >
              Preview
            </span>
          ) : null}
        </div>

        <div className={styles.video_preview}>
          <video ref={videoRef} src={DirectusImage(video_id)} controls />
        </div>
        <div className={styles.divider} />
      </div>
    )
  );
};

export default Project_Video;
