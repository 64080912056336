import React, { useEffect, useRef, useState } from "react";
import styles from "./Equalizer.module.scss";
import { DirectusAudio } from "app/core/common";

type EqualizerProps = {
  playing: boolean;
  equalizerRef: React.RefObject<HTMLButtonElement>;
  setPlaying: (playing: boolean) => void;
  song: string;
  classes?: string;
  barLimit?: number;
};

export default function Equalizer({
  playing,
  equalizerRef,
  setPlaying,
  song,
  classes,
  barLimit = 7,
}: EqualizerProps) {
  let context, analyser, dataArray;
  const [audioReady, setAudioReady] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const visualizerRef = useRef<HTMLDivElement>(null);
  const togglePlayBtnRef = useRef<HTMLButtonElement>(null);

  const handleAudioEnded = () => {
    setPlaying(false); // Set playing to false when the audio ends
  };

  useEffect(() => {
    if (audioRef.current) {
      setAudioReady(true);
    }
  }, []);

  useEffect(() => {
    if (audioReady) {
      preparation();
    }
  }, [audioReady]);

  const togglePlayer = () => {
    if (!context) {
      preparation();
    }
    if (audioRef.current?.paused) {
      playAudio();
    } else {
      pauseAudio();
    }
  };

  const playAudio = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.play();
      loop();
      if (togglePlayBtnRef.current) {
        togglePlayBtnRef.current.innerHTML = `<svg viewBox="0 0 448 512" title="pause">
            <path d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z" />
        </svg>`;
      }
    }
  };

  const pauseAudio = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      if (togglePlayBtnRef.current) {
        togglePlayBtnRef.current.innerHTML = `<svg viewBox="0 0 448 512" title="play">
            <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z" />
        </svg>`;
      }
    }
  };

  const preparation = () => {
    if (audioRef.current) {
      context = new AudioContext();
      analyser = context.createAnalyser();
      const src = context.createMediaElementSource(audioRef.current);
      src.connect(analyser);
      analyser.connect(context.destination);
      loop();
    }
  };

  const loop = () => {
    if (audioRef.current?.paused) {
      return;
    }

    window.requestAnimationFrame(loop);

    if (analyser) {
      dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      changeTracks();
    }
  };

  const changeTracks = () => {
    const visualizer = visualizerRef.current;
    if (visualizer && dataArray) {
      for (let i = 0; i < visualizer.children.length; i++) {
        (visualizer.children[i] as HTMLElement).style.height = `${
          dataArray[i * 5] * 0.39215686274
        }%`;
      }
    }
  };
  useEffect(() => {
    if (playing) {
      playAudio();
    } else {
      pauseAudio();
    }
  }, [playing]);

  return (
    <>
      <audio
        ref={audioRef}
        src={DirectusAudio(song)}
        crossOrigin="anonymous"
        onEnded={handleAudioEnded}
      ></audio>
      <div className={`${styles.visualizer} ${classes}`} ref={visualizerRef}>
        {barLimit > 0 &&
          Array.from({ length: barLimit }).map((_, i) => (
            <div key={i} className={styles.track}></div>
          ))}
      </div>
    </>
  );
}
