import { Link } from "react-router-dom";
import styles from "./UserItem.module.scss";
import { DefaultAvatar, DirectusImage, isCreator } from "app/core/common";
import VerifiedIcon from "@mui/icons-material/Verified";
import { blue } from "@mui/material/colors";

export default function UserItem({ user }) {
  const userId = user?.user?.id;
  const userAvatar = user?.user?.avatar?.id;
  const firstName = user?.user?.first_name;
  const displayName = user?.user?.display_name;
  const userName = user?.user?.username;
  const description = user?.user?.description;
  const Creator = user?.user?.role;

  return (
    <>
      <div className={styles.user_item_container}>
        <Link to={`/user/${userName ?? userId}/posts`}>
          <div className={styles.user_item_avatar}>
            <img
              src={userAvatar ? DirectusImage(userAvatar) : DirectusImage(DefaultAvatar)}
              alt="loop_avatar_img"
            />
          </div>
        </Link>
        <Link to={`/user/${userName ?? userId}`} style={{ color: "#fff" }}>
          <div className={styles.user_item_info}>
            <div className={styles.user_displayname}>
              {displayName ?? firstName}
              {isCreator(Creator) ? (
                <VerifiedIcon fontSize="small" sx={{ color: blue[400] }} />
              ) : null}
            </div>

            <div className={styles.user_username}>
              {userName ? <>@{userName}</> : null}
            </div>
            <div className={styles.user_description}>
              <p>{description ? description : null}</p>
            </div>
          </div>
        </Link>
      </div>
      <hr />
    </>
  );
}
