import { Trans } from "react-i18next";
import styles from "./Follows.module.scss";
import UserItem from "app/components/Profile/user_item/UserItem";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { ProfileListType } from "app/core/common";

type FollowProps = {
  follows: string;
  following: ProfileListType[];
  followers: ProfileListType[];
  followListPage: number;
  setFollowListPage: (page: number) => void;
};

export default function Follows({
  follows,
  following,
  followers,
  followListPage,
  setFollowListPage,
}: FollowProps) {
  //React implementation of the Intersection Observer API to tell you when an element enters or leaves the viewport.
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });
  // render the first ten elements at the list, to reduce charging the info
  const [count, setCount] = useState(10);

  // when the inView is true, it will change the count state to show more elements
  const handleInViewChange = (inView) => {
    if (inView) {
      showMore();
    }
  };

  // function to show more elements
  const showMore = () => {
    setCount((prev) => prev + 10);
  };

   /**********************/
  // scroll event callback function
  /**********************/
  const onScroll = () => {
    // check tab name
    const { scrollTop, scrollHeight } = document.documentElement;
    if (
      window.innerHeight + scrollTop >= scrollHeight
    ) {
      setFollowListPage(followListPage + 1)
    }
  };

  /**********************/
  // Scroll Event
  /**********************/
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return (
    <div className={styles.container}>
      {follows === "following" ? (
        <h1 className={styles.title}>
          <Trans>following</Trans>
        </h1>
      ) : (
        <h1 className={styles.title}>
          <Trans>followers</Trans>
        </h1>
      )}
      <div className={styles.follows_list}>
        {/*if the tabname its following, render the following users list, and render the fist ten, 
        if the count its less than the users list it will display a div that at the moment the creen arrives to it,
        execute the funtion to show ten more users and so on till finish the list */}
        {follows === "following" ? (
          following?.length > 0 ? (
            <>
              {following?.slice(0, count).map((user) => (
                <UserItem key={user.id} user={user} />
              ))}
              {count < following?.length && (
                <InView as="div" onChange={handleInViewChange}>
                  <div
                    ref={ref}
                    className="view_comments"
                    onClick={() => showMore()}
                    style={{ margin: "16px" }}
                  >
                    see more...
                  </div>
                </InView>
              )}
            </>
          ) : (
            <Trans>notFollowing</Trans>
          )
        ) : follows === "followers" ? (
          followers?.length > 0 ? (
            <>
              {followers?.slice(0, count).map((user) => (
                <UserItem user={user} />
              ))}
              {count < followers?.length && (
                <InView as="div" onChange={handleInViewChange}>
                  <div
                    ref={ref}
                    className="view_comments"
                    onClick={() => showMore()}
                    style={{ margin: "16px" }}
                  >
                    see more...
                  </div>
                </InView>
              )}
            </>
          ) : (
            <Trans>notFollowers</Trans>
          )
        ) : null}
      </div>
    </div>
  );
}
