import { useEffect, useState } from "react";
import * as api from "../../api/user.api";
import TimeAgo from "app/helpers/timeago";
import { FeedObject, NFT } from "app/core/common";
import { getMyNFTs } from "app/api/nfts.api";
import { useHook } from "app/hooks/common";
import { useFeed } from "../feedContext";

const useUserApi = () => {
  const { address, cookie } = useHook();
  const { feedLoading } = useFeed();
  const  [checkingUserInfo, setCheckingUserInfo] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [followingListLoaded, setFollowingListLoaded] = useState(false);
  const [followingList, setFollowingList] = useState([]);

  const [feed, setFeed] = useState([]);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [myNFTsLoading, setMyNFTsLoading] = useState(true);

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [myNfts, setMyNfts] = useState<NFT[]>([]);
  const [page, setPage] = useState(1);
  const [nftPagination, setNftPagination] = useState<any>();

  const updateUserInfo = async () => {
    console.log("Updating User Info");
    if (address && cookie) {
      const [userInfo] = await api.fetchUserData({ cookie, address });
      setUserInfo(userInfo);
    }
  }

  /* ======================== */
  /* ==== Fetch User Data ==== */
  /* ======================== */
  useEffect(() => {
    const fetchUserData = async () => {
      if (address && cookie) {
        return api.fetchUserData({ cookie, address });
      }
    };
  
    const fetchUserFollowing = async (userInfo) => {
      if (address && userInfo && cookie) {
        return api.fetchUserFollowing({ cookie, userInfo });
      }
    };
  
    const fetchAllData = async () => {
      if (address && cookie) {
        const [userInfo] = await Promise.all([fetchUserData()]);
        //const followingList = await fetchUserFollowing(userInfo[0]);
        setUserInfo(userInfo[0]);
        setCheckingUserInfo(false);
        //setFollowingList(followingList);
        //setFollowingListLoaded(true);
      } else {
        setCheckingUserInfo(false);
      }
    };
  
    fetchAllData();
  }, [address, cookie]);

  /* ======================== */
  /* ==== Fetch My NFTs ==== */
  /* ======================== */
  useEffect(() => {
    const fetchUserNFTs = async () => {
      if (address && userInfo && cookie) {
        getMyNFTs({ address, page }).then((data) => {
          if (Array.isArray(data.nfts)) {
            setMyNfts([...myNfts, ...data.nfts]);
          }
          setNftPagination(data.pagination);
          setMyNFTsLoading(false);
        });
      }
    };
    fetchUserNFTs();
  }, [address, userInfo, page]);

  /* ======================== */
  /* ==== Update My NFTs ==== */
  /* ======================== */
  const updateMyNFTs = async () => {
    if (address && userInfo && cookie) {
      getMyNFTs({ address, page }).then((data) => {
        if (Array.isArray(data.nfts)) {
          setMyNfts([...data.nfts]);
        }
        setNftPagination(data.pagination);
        setMyNFTsLoading(false);
      });
    }
  };

  /**************************************/
  // Fetch More NFTs
  /**************************************/
  const loadMoreNFTs = () => {
    const totalPages = nftPagination?.total_pages > nftPagination?.current_page;
    //console.log("Load More", totalPages);
    if (totalPages) {
      setMyNFTsLoading(true);
      setPage(page + 1); // Increment page number
    }
  };

  return {
    userInfo,
    checkingUserInfo,
    followingList,
    followingListLoaded,
    lastUpdated,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList: setFollowingList,
    updateUserInfo: updateUserInfo,
  };
};
export default useUserApi;
