import "./App.scss";
import Sidebar from "./sidebar/Sidebar";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import Cookie from "js-cookie";
import Feed from "app/pages/Feed";
import AuthenticatedRoute from "AuthenticatedRoute";
import LoginPage from "app/pages/Login/Login";
import Profile from "app/pages/Profiles/Profile";
import Course from "app/pages/Courses/index";
import Collections from "app/pages/Collection/index";
import { NotificationContext } from "app/context/notification";
import { useCallback, useEffect, useRef, useState } from "react";
import NotificationModals from "app/components/notificationModals";
import TransactionStatus from "app/pages/TransactionStatus/status";
import ClubView from "app/pages/Clubs/ClubView";
import ScrollToTop from "ScrollToTop";
import NftDetail from "app/pages/NftDetail";
import DiscoverView from "app/pages/Discover/DiscoverView";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { useLogin } from "app/context/loginProvider";
import Modal from "app/components/Modal";
import { Trans } from "react-i18next";
import Button from "app/components/button/button";
import { t } from "i18next";
import ArtistSignup from "app/pages/ArtistSignUp/ArtistSignup";
import CreateLoop from "app/pages/CreateLoop/CreateLoop";
import LeaderBoard from "app/pages/Leaderboard/LeaderBoard";
import Admin from "app/pages/admin/Admin";
import axios from "axios";
import EarnPoints from "app/pages/EarnPoints/EarnPoints";
import RemixContest from "app/pages/EarnPoints/RemixContest";
import { DirectusImage, isCreator } from "app/core/common";
import { includes } from "ramda";
import Collaboration from "app/pages/Collaboration/Collaboration";
import Inbox from "app/pages/Inbox/Inbox";
import CollectionsView from "app/pages/Collections/CollectionsView";
import HeadsetIcon from "@mui/icons-material/Headset";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import Loader from "app/components/Loader";
import MusicLibrary from "app/pages/MusicLibrary";
import PlayerComponent from "app/components/mediaPlayerComponent";
import { useMediaPlayer } from "./app/context/mediaPlayerContext";
import CloseIcon from "@mui/icons-material/Close";
import OnboardModal from "app/components/onboardModal";

/* interface BugpilotOptions {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionStatus?: string;
} */

/* declare global {
  interface Window {
    Bugpilot: {
      identify: (options: BugpilotOptions) => void;
      logout: () => void;
    };
  }
} */

function App() {
  const { address, cookie, userInfo } = useHook();
  const [type, setType] = useState(undefined);
  const [subType, setSubType] = useState(undefined);
  const [txHash, setTxHash] = useState(undefined);
  const [onOk, setOnOk] = useState(undefined);
  const [txErrorMessage, setTxErrorMessage] = useState(undefined);
  const [redirectURL, setRedirectURL] = useState("");
  const [themeData, setThemeData] = useState<any>({ theme: {} });
  const { showPlayerModal, toggleMediaPlayerModal, handleTrack, handleTracks } =
    useMediaPlayer();

  const location = useLocation();

  const isCollectionPage = location.pathname.startsWith("/collection");

  useEffect(() => {
    const playerCookie = Cookie.get("mediaPlayerOpen");
    const albumSongIndex = Cookie.get("currentIndex");
    const tracks = Cookie.get("tracks");
    const songCookie = Cookie.get("lastPlayedSong");

    if (playerCookie === "true" && songCookie) {
      try {
        const songInfo = JSON.parse(songCookie);
        toggleMediaPlayerModal(true);
        handleTrack(songInfo);
      } catch (error) {
        console.error("Failed to parse songCookie:", error);
        // Handle the error (e.g., clear the invalid cookie)
        Cookie.remove("lastPlayedSong");
        Cookie.remove("AlbumlastPlayedSong");
        toggleMediaPlayerModal(false);
      }
    } else if (playerCookie === "true" && albumSongIndex && tracks) {
      try {
        const trackss = JSON.parse(tracks);
        const currentIndex = parseInt(albumSongIndex, 10);
        toggleMediaPlayerModal(true);
        handleTracks(trackss, currentIndex);
      } catch (error) {
        console.error("Failed to parse songCookie:", error);
        // Handle the error (e.g., clear the invalid cookie)
        Cookie.remove("currentIndex");
        Cookie.remove("tracks");
        toggleMediaPlayerModal(false);
      }
    } else {
      toggleMediaPlayerModal(false);
    }
  }, []);

  const setTheme = useCallback((theme) => {
    setThemeData({ theme: theme });
    setType(theme?.type);
    setSubType(theme?.subType);
    setTxHash(theme?.txHash);
    setOnOk(theme?.onOk);
    setTxErrorMessage(theme?.txErrorMessage);
    if (theme?.redirectURL !== undefined) {
      setRedirectURL(theme?.redirectURL);
    }
  }, []);

  /* useEffect(() => {
    if (window.Bugpilot && userInfo) {
      window.Bugpilot.identify({
        id: userInfo.id,
        firstName: userInfo.first_name,
        subscriptionStatus: "active",
      });
    }
  }, [userInfo]); */

  const [state, setState] = useState<any>({
    theme: {
      type: undefined,
      subType: undefined,
      txHash: undefined,
      onOk: undefined,
      txErrorMessage: undefined,
      redirectURL: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTheme,
  });
  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      theme: {
        type,
        subType,
        txHash,
        onOk,
        txErrorMessage,
        redirectURL,
        ...themeData.theme,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTheme,
    }));
  }, [
    type,
    subType,
    txHash,
    onOk,
    txErrorMessage,
    redirectURL,
    setTheme,
    themeData.theme,
  ]);

  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpen = window.innerHeight < window.screen.height;

      if (isKeyboardOpen) {
        document.body.style.height = "100vh";
        // Other styling adjustments as needed
      } else {
        document.body.style.height = "auto";
        // Reset other styles
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerRef = useRef(null);
  const { showLoginModal, showSignup, artistSignup, showRegister } = useLogin();

  /* Check UserAgent on iPhone */
  /*
    let standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  */

  // check UserAgent on Android
  const ua = navigator.userAgent;
  const isInstagram = ua.indexOf("Instagram") > -1 ? true : false;

  const InAppModal = () => {
    const [copy, setCopy] = useState(false);
    {
      /* IN APP DETECTION */
    }
    return (
      <Modal
        isOpen={isInstagram}
        onClose={() => {}}
        title={t("browserNotice")}
        customClass="pauseModal"
      >
        <div
          style={{ textAlign: "center", marginBottom: "50px", padding: "20px" }}
          className="pauseCard"
        >
          <Trans>embeddedWebView</Trans>
          <br />
          <br />
          <p>
            <b>Step 1:</b> <Trans>embeddedWebViewStep1</Trans>
          </p>
          <p>
            <b>Step 2:</b> <Trans>embeddedWebViewStep2</Trans>
          </p>
          <br />
          <p>
            <b>or</b>
          </p>
          <br />
          <p>
            <Trans>embeddedWebViewStep3</Trans>
          </p>
          <br />
          <br />
          {/* Show Toast on button click */}
          <Button
            className="btn_full btn_sm btn_solid"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopy(true);
              setTimeout(() => {
                setCopy(false);
              }, 1000);
            }}
          >
            {!copy ? "Copy Link" : "Copied"}
          </Button>
          <br />
        </div>
      </Modal>
    );
  };

  // ************************* //
  // Analytics for page views
  // ************************* //
  useEffect(() => {
    // check if localhost
    const sendRouteChange = async () => {
      const isLocalhost = window.location.hostname === "localhost";
      if (cookie && !isLocalhost) {
        axios(`${process.env.REACT_APP_MIDDLEWARE}arena/pageView`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            path: location.pathname,
            cookie: cookie,
          },
        });
      } else if (
        !cookie &&
        !isLocalhost &&
        location.pathname.includes("artist-signup")
      ) {
        axios(`${process.env.REACT_APP_MIDDLEWARE}arena/pageView`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            path: location.pathname,
          },
        });
      }
    };

    sendRouteChange();
  }, [location, cookie]);

  return (
    <div className="App">
      <ScrollToTop />
      <NotificationContext.Provider value={state}>
        <NotificationModals />
        <LoginModal
          showLoginModal={showLoginModal}
          showSignup={showSignup}
          artistSignup={artistSignup}
          showRegister={showRegister}
        />
        {address && !userInfo?.onboard && !isCollectionPage ? (
          <OnboardModal />
        ) : null}
        {showPlayerModal && address ? <PlayerComponent /> : null}

        <Switch>
          {/* ArtistSignUp */}
          <Route path="/artist-signup" component={ArtistSignup} exact />

          {/* ArtistSignUp */}
          <Route path="/create-loop" component={CreateLoop} exact />

          {/* Sidebar with Routes */}
          <Route>
            <Sidebar isLoggedIn={address} containerRef={containerRef}>
              {/* Login Route */}
              <Route path="/login/" component={LoginPage} exact />

              {/* AdminRoute */}
              <Route path="/admin/nftgen" component={Admin} exact />

              {/* LOOPS */}
              <Route path="/loops/" component={ClubView} exact />

              {/* Discover */}
              <Route path="/" component={DiscoverView} exact />

              {/* Collection */}
              <Route path="/collection/:id" component={Collections} exact />

              {/* Collections Page */}
              <Route path="/collections" component={CollectionsView} exact />

              {/* NFT Detail View */}
              <Route path="/nftDetail/:id" component={NftDetail} exact />

              {/* leaderboard*/}
              <Route path="/leaderboard/" component={LeaderBoard} exact />

              {/* collaboration*/}
              <Route
                path="/collaboration/:genre?"
                component={Collaboration}
                exact
              />
              {/*inbox*/}
              <Route path="/inbox/:id?" component={Inbox} exact />

              {/* Leaderboard Page */}
              <Route
                path="/wma/:type?/:genreId?/:divisionId?/:limit?/:page?"
                component={LeaderBoard}
                exact
              />

              {/* EarnPoints */}
              <Route path="/earn-points" component={EarnPoints} exact />

              {/* Remix Contest */}
              {/* {<Route path="/remix-contest" component={RemixContest} exact />} */}

              {/* Courses Page */}
              <AuthenticatedRoute
                path="/courses/:id"
                component={Course}
                containerRef={containerRef}
                exact
              />

              <AuthenticatedRoute
                path="/music-library"
                component={MusicLibrary}
                containerRef={containerRef}
                exact
              />

              {/* Transaction Status */}
              <Route path="/transaction" component={TransactionStatus} exact />

              {/* Creator Profile */}
              <Route
                path="/user/:username/"
                render={() => <Profile containerRef={containerRef} />}
                exact
              />
              <Route
                path="/user/:username/:id"
                render={() => <Profile containerRef={containerRef} />}
                exact
              />

              {/* Feed */}
              <AuthenticatedRoute
                path="/feed"
                exact
                component={Feed}
                containerRef={containerRef}
              />
            </Sidebar>
          </Route>
        </Switch>
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
