import Button from "app/components/button/button";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useWallet } from "cloud-social-wallet";
import styles from "./styles.module.scss";

const InstaGramPage = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        margin: "auto",
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <div>
        <div className={styles.wallet_login_wrapper}>
          <h1 style={{ fontSize: "24px" }}>Check your email to continue</h1>
        </div>
      </div>
    </div>
  );
};

export default InstaGramPage;
